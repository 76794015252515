<template>
  <li :style="isSlider ? 'max-width: 90%' : ''">
    <article>
      <a
        class="uk-overflow-hidden uk-display-block uk-position-relative"
        style="aspect-ratio: 1 / 1"
        :href="product.href"
      >
        <img
          class="uk-position-center"
          style="object-fit: cover; width: 100%; height: 100%"
          :data-src="hoverImage"
          data-uk-img
          :alt="product.name"
        />
        <img
          class="vb-hover-img uk-position-center vb-hover-scale"
          style="object-fit: cover; width: 100%; height: 100%"
          :data-src="mainImage"
          data-uk-img
          :alt="product.name"
        />
        <!--        <img-->
        <!--          v-if="product.isOnSale"-->
        <!--          class="uk-position-top-right uk-width-1-6@m uk-width-1-4 vb-product-label"-->
        <!--          style="max-width: 60px; margin: 16px; transform: rotate(-30deg)"-->
        <!--          data-src="/resources/assets/frontend/img/brand/label-sale.svg"-->
        <!--          data-uk-img-->
        <!--          alt="Sale"-->
        <!--        />-->
        <img
          v-if="product.isNew"
          class="uk-position-top-right uk-width-1-6@m uk-width-1-4 vb-product-label"
          style="max-width: 60px; margin: 16px; transform: rotate(-30deg)"
          :data-src="getImgPath('/img/brand/label-new.svg')"
          data-uk-img
          alt="New"
        />
      </a>
      <div class="uk-padding-small">
        <h3 :class="['uk-h6 vb-font-family-global uk-margin-small-bottom', { 'uk-h5 uk-width-4-5': isSlider }]">
          <a :href="product.href">{{ product.title }}</a>
        </h3>
        <p class="uk-margin-remove">
          <template v-if="product.isOnSale">
            <span class="uk-text-bold" :style="isSlider ? 'font-size: 1.125rem' : ''">
              {{ getFormattedPrice(product.prices.sale) + ' ' }}
            </span>
            <del :class="['uk-text-muted', { 'uk-text-small': !isSlider }]">
              {{ getFormattedPrice(product.prices.retail) }}
            </del>
          </template>
          <span v-else class="uk-text-bold" :style="isSlider ? 'font-size: 1.125rem' : ''">
            {{ getFormattedPrice(product.prices.retail) }}
          </span>
        </p>
      </div>
    </article>
  </li>
</template>

<script>
import noImageImg from '@/web/img/brand/placeholder-light.svg'
import { getImgPath } from '@/shared/js/helpers'
import { getFormattedPrice } from '@/web/js/helpers'

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainImage() {
      return this.product?.images[0] ?? getImgPath('/img/brand/placeholder-light.svg')
    },
    hoverImage() {
      const img = this.product.images[1] ?? this.mainImage
      return img && !img?.includes('http') ? noImageImg : img
    },
  },
  methods: { getImgPath, getFormattedPrice },
}
</script>
